<template>
  <v-container fluid>
    <v-row>
      <v-col class="col-12">
        <h1>Admin - Manage Materials</h1>
        <v-row>
          <v-col class="col-12 col-md-4">
            <v-text-field
              label="Search"
              v-model="searchTerm"
              clearable />
          </v-col>
          <v-col class="col-12 col-md-6">
            <v-btn
              @click="addMaterial = true"
              small
              class="float-right">
              Add Material
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="col-12 col-md-8">
            <v-simple-table
              fixed-header
              height="300"
            >
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">Favourite</th>
                    <th class="text-left">Material</th>
                    <th class="text-left">Sage Nominal</th>
                    <th>&nbsp;</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(material, fIndex) in filteredMaterials"
                    :key="fIndex"
                  >
                    <td>
                      <input
                          @change="markFavourite(fIndex)"
                          type="checkbox"
                          :checked="material.favourite">
                    </td>
                    <td>
                      <v-text-field v-model="material.material"></v-text-field>
                    </td>
                    <td>
                      <v-select v-model="material.sage_nominal" :items="nominals"></v-select>
                    </td>
                    <td>
                      <v-btn small @click="archive(fIndex)">
                        Archive
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
        </v-row>
        <v-row class="pt-10">
          <v-col>
            <v-btn @click="saveChanges">Save Changes</v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-pagination
      class="pt-4"
      v-model="page"
      :length="length"
      prev-icon="mdi-menu-left"
      next-icon="mdi-menu-right"
      :total-visible="totalVisible"
    ></v-pagination>
    <div
      class="modal"
      v-if="addMaterial">
      <v-icon
        class="close"
        @click="addMaterial = false"
      >mdi-close-circle</v-icon>
      <v-form>
        <v-row>
          <v-col>
            <v-text-field
              v-model="newMaterial.material"
              label="Material" />
          </v-col>
          <v-col>
            <v-select
              :items="nominals"
              v-model="newMaterial.nominal"
              label="Nominal"></v-select>
          </v-col>
          <v-col class="pt-8">
            <v-btn
              @click="saveNewMaterial" small>Save Material</v-btn>
          </v-col>
        </v-row>
      </v-form>
    </div>
  </v-container>
</template>

<script>
import axios from '../../../axios';

export default {
  name: 'AdminMaterialsIndex',
  computed: {
    token() {
      return this.$store.state.token;
    },
  },
  data() {
    return {
      page: 1,
      length: 0,
      materials: [],
      filteredMaterials: [],
      searchTerm: null,
      addMaterial: false,
      newMaterial: {},
      nominals: [ 4001,4003,4008,4015,4020,4025,4035,4040 ],
      totalVisible: 9,
    };
  },
  watch: {
    searchTerm() {
      this.filterMaterials();
    },
    page() {
      this.getMaterials();
    },
  },
  methods: {
    saveChanges() {
      const postData = {}
      postData.materials = this.filteredMaterials;
      axios.post(`/materials/saveUpdates/.json?token=${this.token}`, postData)
        .then(() => {
          this.getMaterials();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    archive(index) {
      const material = this.filteredMaterials[index];
      axios.get(`/materials/archive/${material.value}.json?token=${this.token}`)
        .then((response) => {
          this.materials = response.data;
          this.filteredMaterials = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    saveNewMaterial() {
      const postData = {};
      postData.material = this.newMaterial;
      axios.post(`/materials/create/.json?token=${this.token}`, postData)
        .then(() => {
          this.addMaterial = false;
          this.newMaterial = {};
          this.getMaterials();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    filterMaterials() {
      this.filteredMaterials = this.materials;
      if (this.searchTerm) {
        this.filteredMaterials = this.materials.filter((material) => material.material.toLowerCase().includes(
          this.searchTerm.toLowerCase(),
        ));
      }
    },
    getMaterials() {
      axios.get(`/materials/getAllFull/${this.page}.json?token=${this.token}&term=${this.searchTerm}`)
        .then((response) => {
          this.materials = response.data.materials;
          this.filteredMaterials = response.data.materials;
          this.length = response.data.length;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    markFavourite(index) {
      const material = this.filteredMaterials[index].id;
      axios.get(`/materials/markFavourite/${material}.json?token=${this.token}&term=${this.searchTerm}&userid=${this.userid}`);
    },
  },
  mounted() {
    this.getMaterials();
  },
};
</script>
